.skillsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 3.5rem;
    padding: 0 2rem;
    gap: 20px;
  }

  .skill--scroll {
    width: 100%;
    margin: 0 2rem;
  }

  .skills {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 60vh;
    padding: 2.5rem 2rem 2rem 2rem;
  }



  .skill--box {
    background: #fafafa;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    width: 160px;
    height: 160px;
    margin: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 1rem;
    transition: 300ms ease-in-out;
  }
  
  .skill--box:hover {
    transform: scale(1.15);
  }
  
  .skill--box > img {
    height: 50px;
    pointer-events: none;
  }
  
  .skill--box h3 {
    font-family: Big Shoulders Text;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    margin-top: 1rem;
    color: white;
  }

  .skillsHeader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .skillsHeader h2 {
    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: bold;
    font-size: 3.5rem;
    text-align: center;
  }
  

